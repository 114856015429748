
import { Component, Vue } from 'vue-property-decorator';

import Auth from '@/app/lib/auth/Auth';
import UISettings from '@/app/lib/settings/UISettings';
import { redirect } from '@/shared/lib/support/router/RouterUtils';

@Component
export default class AuthLoginView extends Vue {
  /**
   * Lifecycle hooks
   */
  private created() {
    this.logout();
  }

  /**
   * Methods
   */
  private async logout() {
    await Auth.logout();
    this.$router.push({ name: 'auth.login' }); // TODO use redirect()
  }

  /**
   * Render method
   */
  private render() {
    //
  }
}
